import React from 'react';
import { graphql } from 'gatsby';

import DefaultLayout from '../layout/DefaultLayout';

import { InitiativBewerbungsPage as InitiativBewerbungsPageShared } from '@mangoart/ezagrar/pages/initiativbewerbung';

const InitiativBewerbungsPage = ({ data, location }) => {
  return (
    <DefaultLayout>
      <InitiativBewerbungsPageShared data={data} location={location} />
    </DefaultLayout>
  );
};

export default InitiativBewerbungsPage;

export const InitiativPageQuery = graphql`
  query {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
  }
`;
